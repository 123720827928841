import React from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { SearchBox } from 'react-instantsearch-dom';

const query = graphql`
  {
    file(relativePath: { eq: "hero-advices-4447.jpg" }) {
      # file(relativePath: { eq: "hero-advices-447.eps" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

const Hero = () => {
  const { file } = useStaticQuery(query);
  return (
    <div className="flex flex-col">
      <div className="md:mx-auto md:container">
        <div className="pt-6 md:pt-8">
          <div className="container mx-auto">
            <div className="flex flex-wrap items-center pb-12">
              <div className="md:w-1/2 lg:w-2/3 w-full">
                <div className="ml-2 lg:ml-24">
                  <h2 className="title-font sm:text-3xl text-2xl mb-4 font-medium text-gray-900">
                    Busca nuestra extensa base de datos de consejos más veraces
                    sobre el cuidado de la diabetes en la internet
                  </h2>
                  <p className="mb-8 sm:text-xl leading-relaxed">
                    Seleccionamos lo mejor en la internet para tu tranquilidad y
                    comodidad
                  </p>

                  {/* Forma de Envío */}
                  <SearchBox autoFocus className="border w-5/6" />
                  {/* <div className="flex flex-row justify-start w-full max-w-md shadow-xl border-t border-b border-gray-200">
                    <form
                      action=""
                      className="flex flex-row justify-start w-full max-w-md shadow-xl border-t border-b border-gray-200"
                      onSubmit={submitForm}
                    >
                      <input
                        ref={inputRef}
                        className="border-l-4 border-red-700 bg-white focus:outline-none px-4 w-full m-0"
                        type="email"
                        name="email"
                        id="email-input"
                        placeholder="Email"
                      />
                      <button
                        className="inline-flex text-black py-2 px-6 focus:outline-none text-lg m-0 h-12 rounded border-2 border-themeBrandColor border-solid bg-themeBrandColor hover:bg-white text-white hover:text-themeBrandColor "
                        type="submit"
                      >
                        Suscribirme
                      </button>
                    </form>
                  </div> */}
                </div>
              </div>
              <div className="lg:w-1/3 md:w-1/2 w-full relative h-96 flex items-end justify-center">
                <div className="relative z-10 p-2 w-10/12">
                  <GatsbyImage
                    image={file.childImageSharp.gatsbyImageData}
                    alt="hero image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
